<template>
  <div class="home">
    <div class="container">
      <div class="home__wrapper">
        <card v-for="(card, index) in cards" :key="index" :card-url="card.cardUrl" :image-url="card.imageUrl"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from '../components/Card'

const cards = [
  {
    imageUrl: 'card_1.png',
    cardUrl: '/wedding'
  },
  {
    imageUrl: 'card_3.png',
    cardUrl: '/wedding/third'
  },
]
</script>

<style scoped lang="scss">
.home {
  background: #f1f1f1;
  padding: 60px 0;
  width: 100%;
  min-height: 100vh;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    align-items: center;
    justify-content: center;
  }
}
</style>
