import { createApp } from "vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from "./App.vue";
import "./assets/styles/index.scss"
import router from "./router";
import 'aos/dist/aos.css'
import { languages } from '@/i18n'
import { defaultLocale } from '@/i18n'
import {createI18n, useI18n} from 'vue-i18n'

const messages = Object.assign(languages)
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'ru',
  messages
})

createApp(App, {
  setup() {
    const {t} = useI18n()
    return {t}
  }
}).use(i18n).use(router).use(ElementPlus).mount("#app");
