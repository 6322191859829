import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import WeddingThird from "../views/WeddingThird";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/main",
      name: "Home",
      component: Home,
    },
    {
      path: "/about",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/About.vue"),
    },
    {
      path: "/wedding",
      name: "Wedding",
      component: () => import("../views/Wedding.vue"),
    },
    {
      path: "/wedding/second",
      name: "WeddingSecond",
      component: () => import("../views/WeddingSecond.vue"),
    },
    {
      path: "/wedding/third",
      name: "WeddingThird",
      component: WeddingThird,
    },
    {
      path: "/wedding/fourth",
      name: "WeddingFourth",
      component: () => import("../views/WeddingFourth.vue"),
    },
    {
      path: "/wedding/fifth",
      name: "WeddingFifth",
      component: () => import("@/views/WeddingFifth.vue"),
    },
    {
      path: "/marsel-aida",
      name: "MarselAida",
      component: () => import("@/views/MarselAida"),
    }
  ]
});

export default router;
