<template>
  <div class="card">
    <router-link :to="props.cardUrl" >
      <div class="card-wrapper">
        <div class="card__image">
          <img :src="require(`../assets/images/${props.imageUrl}`)" alt="">
        </div>
        <!--      <div class="card__controls">-->
        <!--        <router-link class="card__button" :to="props.cardUrl">-->
        <!--          Ознакомиться-->
        <!--        </router-link>-->
        <!--      </div>-->
      </div>
    </router-link>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'
const props = defineProps(['cardUrl', 'imageUrl'])
</script>

<style lang="scss" scoped>
.card {
  width: 240px;
  height: 460px;
  padding: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.87);
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__image {
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      aspect-ratio: 1/2;
      border-radius: 8px;
    }
  }
  &__controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__button {
    color: black;
    font-size: 24px;
  }
}
</style>