<template>
  <div class="timer">
    <div v-for="(v, k) in remainingTime" :key="k" class="timer__item">
      <div>
        {{ v }}
      </div>
      <div>
        {{ forms[k][pr.select(v)] }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, ref, onUnmounted } from "vue"

const props = defineProps({
  date: Date
})

const remainingTime = ref({})
const pr = new Intl.PluralRules('ru');

const forms = {
  days: { one: 'день', few: 'дня', many: 'дней' },
  hours: { one: 'час', few: 'часа', many: 'часов' },
  minutes: { one: 'минута', few: 'минуты', many: 'минут' },
  seconds: { one: 'секунда', few: 'секунды', many: 'секунд' },
}

let interval = null

onMounted(() => {
  interval = setInterval(updateCountdown, 1000)
})

onUnmounted(() => {
  clearInterval(interval)
})

function updateCountdown() {
  const ms = props.date - Date.now();

  remainingTime.value = Object.fromEntries([
    [ 'days', ms / (1000 * 60 * 60 * 24) ],
    [ 'hours', (ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) ],
    [ 'minutes', (ms % (1000 * 60 * 60)) / (1000 * 60) ],
    [ 'seconds', (ms % (1000 * 60)) / 1000 ],
  ].map(n => [ n[0], `${Math.floor(n[1])}`.padStart(2, 0) ]));
}
</script>

<style scoped lang="scss">
.timer {
  display: flex;
  gap: 16px;
  @include responsive(md) {
    gap: 20px;
  }
  &__item {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include responsive(md) {
      font-size: 28px;
    }
   }
}
</style>