<template>
  <router-view />
</template>

<style lang="scss">
#app {
  height: 100%;
  &:has(.wedding-third) {
    background: #fffffc;
  }
}
</style>
