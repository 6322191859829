export const MockQuestionsArr = [
  {
    title: 'Подарки',
    text:
      'Вашим подарком на нашей свадьбе станет ваше присутствие! Очень надеемся' +
      ' увидеть каждого из вас. <br>' +
      ' P.S. Если вы никак не можете уговорить себя прийти с «пустыми руками», ' +
      'мы предоставим вам особую возможность «сделать нам приятно» во время ' +
      'праздника. Просто доверьтесь нам!',
  },
  {
    title: 'Дети',
    text:
      'Поскольку у нас планируется семейный праздник - мы ждём ваших деток так же сильно, как и вас',
  },
  {
    title: 'Оставшиеся вопросы',
    text:
      'Касательно любых вопросов и возникающих сложностей мы с удовольствием' +
      ' ответим вам напрямую по телефону: <a class="questions__phone-link" href="tel:+70000000000"> +7 (000) 000-00-00 </a>',
  }
]

export const mockSchedule = [
  {
    iconName: 'location',
    title: '17:00 Сбор гостей',
    text: 'присоединяйтесь в нашу счастливую и незабываемую свадебную сказку'
  },
  {
    iconName: 'wedding',
    title: '17:30 Выездная цеременония',
    text: 'станьте свидетелями нашей любви и обмена клятвами'
  },
  {
    iconName: 'toast',
    title: '19:00 Начало банкета',
    text: 'разделите начало нашего совместного пути, проводя незабываемый вечер вместе с нами'
  },
]